import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import ValuesLinks from "../components/ValuesLinks";
import ScrollAnimation from "../components/ScrollAnimation";
import { Helmet } from "react-helmet";

const title = "Afecțiune & Ajutorare";

const AfectiunePage: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    headlines {
                        afectiune,
                        ajutorare
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Helmet title={title} />
            <Breadcrumb title={title} />

            <section className="page-area section-default section-padding-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="image-bordered">
                                <StaticImage
                                    src="../images/afectiune.jpg"
                                    alt={title}
                                />
                            </div>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3 className="headline">{data.site.siteMetadata.headlines.afectiune} {data.site.siteMetadata.headlines.ajutorare}</h3>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp">
                                <blockquote className="blockquote">
                                    <p>&quot;...slujiți-vă unii altora în dragoste.&quot;</p>
                                    <footer>Galateni 5:13</footer>
                                </blockquote>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Am înțeles că Dumnezeu ne-a binecuvântat pentru a fi mai departe o binecuvântare pentru alții.</p>
                            </ScrollAnimation>

                            <div className="height-50"></div>

                            <ValuesLinks active={4} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default AfectiunePage;
